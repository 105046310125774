import Link from "gatsby-link";
import { get } from "lodash";
import React from "react";
import styled from "styled-components";
import { A, ACSS, H5, IconBook, Small } from "@upsolve/ui";

/**
 * RelatedArticles
 * - v0: Show should I file chap 7 + 3 passed in articles
 *
 * @component
 * @version 0
 */
const RelatedArticles = ({ articles, includeHardcoded = true, limit = 4 }) => {
  // Stuff this w/ should I file and 7vs13 articles since we want heavy linking to those
  const articlesRendered = [
    includeHardcoded
      ? {
          slug: "should-i-file-for-chapter-7-bankruptcy",
          title: "Should I File Chapter 7 Bankruptcy?",
        }
      : null,
    ...articles.filter(
      (a) =>
        !a.slug.includes("should-i-file-for-chapter-7-bankruptcy") &&
        !a.slug.includes("chapter-7-versus-chapter-13-bankruptcy")
    ),
    includeHardcoded
      ? {
          slug: "chapter-7-versus-chapter-13-bankruptcy",
          title: "Chapter 7 vs. Chapter 13 Bankruptcy",
        }
      : null,
  ]
    .filter((ra) => ra)
    .slice(0, limit);

  return (
    <StyledRelatedArticles>
      <H5 as="p">Continue reading and learning!</H5>
      <div>
        {articlesRendered.map((article) => (
          <A key={article.slug} href={`/learn/${article.slug}/`}>
            <div className="ra__cover">
              <IconBook />
            </div>
            <div className="ra__title">
              {article.title}
              <Small>
                <br />
                By {get(article, "authors[0].name") || "the Upsolve Team"}
              </Small>
            </div>
          </A>
        ))}
      </div>
    </StyledRelatedArticles>
  );
};

const StyledRelatedArticles = styled.div`
  max-width: 1080px;
  margin: 0 auto 2em;
  & > p {
    width: 100%;
    text-align: center;
    color: ${(props) => props.theme.colors.gray[900]};
    opacity: 0.9;
  }
  & > div {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    & > a {
      height: auto;
      border-radius: 4px;
      margin: 1em 0.5em;
      overflow: hidden;
      background: white;
      box-shadow: 0 4px 16px rgba(0, 0, 25, 0.1);
      display: flex;
      flex-direction: column;
      .ra__cover {
        background: linear-gradient(
          0deg,
          ${(props) => props.theme.colors.white[700]},
          ${(props) => props.theme.colors.white[800]}
        );
        min-height: 32px;
        padding: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        svg path {
          fill: ${(props) => props.theme.colors.white[100]};
        }
      }
      .ra__title {
        height: auto;
        padding: 1em;
        ${ACSS}
        small {
          opacity: 0.5;
        }
      }
    }
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      grid-template-columns: repeat(1, 1fr);
      & > a {
        margin: 1em 2em;
      }
    }
  }
`;

export default RelatedArticles;
